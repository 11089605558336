import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/modules/h-auth/h-auth.service';
import { LocalStorageService } from '@hss-m/uikit-common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  AUTHORIZATION = "Authorization";
  JURISDICTION_IDS = "x-terra-jurisdiction-ids";

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const reqHeaders = [];
    const bearerToken = this.authService.getBearerToken();
    if (bearerToken) {
      reqHeaders.push({key: this.AUTHORIZATION, value: `Bearer ${bearerToken}`});
    }
    const jurisdictionIds = this.localStorageService.getObject(this.JURISDICTION_IDS)?.map(i=>i.id);
    if(jurisdictionIds){
      reqHeaders.push({key: this.JURISDICTION_IDS, value: jurisdictionIds.join(",")});
    }

    if (reqHeaders.length > 0) {
      let headers = new HttpHeaders();
      reqHeaders.forEach(header => {
        headers = headers.set(header.key, header.value);
      });
      const authReq = request.clone({ headers: headers});
      return next.handle(authReq);
    } else {
      return next.handle(request);
    }
  }
}
